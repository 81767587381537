'use client';

import { useState, useEffect } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { useAuth } from '@/context/AuthContext';
import Image from 'next/image';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { Mail, Lock, ArrowRight, AlertCircle, Briefcase, CheckCircle } from 'lucide-react';

export default function LoginComponent() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { signIn, user, loading } = useAuth();
  const router = useRouter();
  const searchParams = useSearchParams();

  const testimonials = [
    {
      name: "Carina Popescu",
      image: "https://randomuser.me/api/portraits/women/10.jpg",
      text: "Am găsit un zugrav excelent în doar câteva minute!",
      role: "Proprietar Apartament",
      rating: 5
    },
    {
      name: "Mihai Ionescu",
      image: "https://randomuser.me/api/portraits/men/5.jpg",
      text: "Platforma a simplificat enorm procesul de renovare a casei mele.",
      role: "Client Mulțumit",
      rating: 5
    }
  ];



  useEffect(() => {
    if (!loading && user) {
      const redirectTo = searchParams.get('redirect');
      if (redirectTo) {
        router.replace(redirectTo);
      } else {
        const role = user?.user_metadata?.role || user?.profile?.role;
        
        switch(role) {
          case 'admin':
            router.replace('/dashboard/admin');
            break;
          case 'worker':
            router.replace('/dashboard/worker');
            break;
          case 'client':
            router.replace('/dashboard/client');
            break;
          default:
            router.replace('/dashboard/client');
        }
      }
    }
  }, [user, loading, router, searchParams]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    setError('');
    setIsLoading(true);

    try {
      const { error: signInError } = await signIn({ email, password });
      if (signInError) throw signInError;
      setIsLoading(false);
    } catch (err) {
      console.error('Login error:', err);
      setError(err.message === 'Invalid login credentials'
        ? 'Email sau parolă incorectă'
        : err.message || 'A apărut o eroare la autentificare');
      setIsLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center">
        <div className="w-full max-w-md bg-white rounded-2xl shadow-xl p-8 animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-6" />
          <div className="space-y-4">
            <div className="h-10 bg-gray-200 rounded" />
            <div className="h-10 bg-gray-200 rounded" />
            <div className="h-12 bg-gray-200 rounded" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center p-4">
      <div className="w-full max-w-[1000px] flex items-stretch gap-8">
        {/* Benefits Section - Hidden on mobile */}
        <motion.div 
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          className="hidden lg:flex flex-col flex-1 bg-white rounded-2xl shadow-md p-8"
        >
          <div className="text-center mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-3">
              De ce să alegi Meseriaș Local?
            </h2>
            <p className="text-gray-600">
              Platforma care conectează clienți cu cei mai buni meseriași
            </p>
          </div>

          <div className="space-y-4 flex-grow">
            {[
              "Acces instant la meseriași verificați",
              "Sistem de rating și review-uri transparent",
              "Prețuri competitive și oferte personalizate",
              "Programări flexibile și răspuns rapid",
              "Suport dedicat pentru fiecare proiect",
              "Garanția calității serviciilor"
            ].map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center space-x-3 bg-blue-50 p-4 rounded-xl"
              >
                <CheckCircle className="h-5 w-5 text-blue-500 flex-shrink-0" />
                <p className="text-gray-700">{benefit}</p>
              </motion.div>
            ))}
          </div>

          <div className="text-center mt-8">
            <p className="text-gray-500">
              Alătură-te celor peste 10,000 de utilizatori mulțumiți
            </p>
          </div>
        </motion.div>

        {/* Login Form */}
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="w-full max-w-md bg-white rounded-2xl shadow-md p-8"
        >
          {/* Logo */}
          <div className="flex justify-center mb-8">
            <Image
              src="/logo.png"
              alt="MeseriașLocal Logo"
              width={150}
              height={150}
              className="rounded-full"
            />
          </div>

          {/* Header */}
          <div className="text-center mb-8">
            <h1 className="text-2xl font-bold text-gray-900">
              Bine ai revenit!
            </h1>
            <p className="text-gray-600 mt-2">
              Conectează-te pentru a continua
            </p>
          </div>

          {/* Error Message */}
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-red-50 text-red-600 p-4 rounded-xl mb-6 flex items-center"
            >
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <p>{error}</p>
            </motion.div>
          )}

          {/* Login Form */}
          <form onSubmit={handleLogin} className="space-y-6">
            <div className="relative">
              <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 
                         focus:ring-blue-500 focus:border-blue-500 transition-all"
                required
              />
            </div>

            <div className="relative">
              <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Parolă"
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 
                         focus:ring-blue-500 focus:border-blue-500 transition-all"
                required
              />
            </div>

            <div className="flex items-center justify-between">
              <Link
                href="/reset-password"
                className="text-sm text-gray-600 hover:text-gray-900 transition-colors"
              >
                Ai uitat parola?
              </Link>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex items-center justify-center px-4 py-3 
                       rounded-xl text-white bg-blue-600 hover:bg-blue-700 
                       transition-colors duration-200 disabled:opacity-50"
            >
              {isLoading ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Se conectează...
                </span>
              ) : (
                <span className="flex items-center">
                  Conectare
                  <ArrowRight className="ml-2 h-5 w-5" />
                </span>
              )}
            </button>
          </form>

          {/* Register Options */}
          <div className="mt-8 pt-6 border-t border-gray-100">
            <p className="text-center text-sm text-gray-600 mb-4">
              Nu ai încă un cont?
            </p>
            <div className="grid gap-4">
              <Link
                href="/register/worker"
                className="flex items-center justify-center px-4 py-3 
                         border border-gray-200 rounded-xl hover:bg-gray-50 
                         transition-colors text-sm text-gray-700"
              >
                <Briefcase className="w-4 h-4 mr-2" />
                Înregistrează-te ca meseriaș
              </Link>
              <Link
                href="/new-post"
                className="flex items-center justify-center px-4 py-3 
                         bg-gray-900 rounded-xl hover:bg-gray-800 
                         transition-colors text-sm text-white"
              >
                <ArrowRight className="w-4 h-4 mr-2" />
                Postează o lucrare ca client
              </Link>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}